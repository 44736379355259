<template>
  <base-section
    id="consulting-engineering"
  >
    <base-section-heading
      icon="mdi-account-wrench"
      title="Consulting·Engineering"
      text="Based on consulting know-how in various industries and abundant factory and warehouse automation design and engineering experience, we will make the best suggestions."
    />
    <v-container>
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        width="100%"
      >
      <h1
        class="text-center"
        style="color:#214987;"
      >The Business Field
      </h1>
      <br>
      <br>
        <v-row>
          <v-col
            cols="3"
            class="text-center"
          >
            <template
              v-for="(card, i) in cards"
            >
              <base-avatar-card
                :key="i"
                align="center"
                v-bind="card"
                @click.native="fnChangeTab(card)"
              />
              <v-divider
                v-if="i + 1 !== cards.length"
                :key="`divider-${i}`"
                class="my-8"
              />
            </template>
          </v-col>

          <v-col
            cols="1"
            class="text-center px-0 mx-0"
          >
            <v-responsive
              height="calc(100% - 16px)"
              class="mx-0 px-0"
            >
              <v-divider
                vertical
                dark
                class="mx-0 px-0"
              />
            </v-responsive>
          </v-col>

          <v-col
            cols="8"
          >
            <base-subheading
              size="text-h5"
              :title="cards[currentTab].title"
              space="0"
              mobileSize="text-h6"
              class="text-uppercase"
            />

            <base-title
              class="primary--text"
              :title="cards[currentTab].location"
              tag="div"
            />
            <base-body>
              {{cards[currentTab].description}}
            </base-body>
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-for="detail in cards[currentTab].details"
                :key="detail"
              >
                <base-list-item
                  :text="detail"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-responsive>
      <br>
      <br>
      <br>
      <br>
      <h1
        class="text-center"
        style="color:#214987;"
      >Implementation Procedure
      </h1>
      <br>
      <br>
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="process in processes"
          :key="process.title"
        >
          <base-info-card-pacific
            v-bind="process"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionConsultingEngineering',

    data: () => ({
      currentTab: 0,
      cards: [
        {
          no: 0,
          title: 'Base & Operational Optimization Consulting',
          description: 'Establish mid- to long-term strategies in line with management policies, rationalize facilities, and establish a mid- to long-term master plan.',
          chosen: true,
          details: [
            'Establishment of Site Analysis and Development Plan/Selection of Center Location',
            'BPR (Business Process Re-engineering)',
            'Organization Rationalization Consulting',
          ],
        },
        {
          no: 1,
          title: 'Automation Consulting',
          description: 'Data analysis, concept layout, facility specification, operation plan, estimated investment amount, etc. are performed in the initial state where RFP, design conditions, etc. are not established, and new center planning, production, procurement, store, and sales system consulting are provided',
          details: [
            'Analysis of data (volume analysis, ABC analysis)',
            'Layout and Feasibility Analysis for Improvement of Current System',
            'Analyze costs and plan for savings',
            'Selection of proposed facilities',
            'IT Solution Package Deployment Consulting',
          ],
          chosen: false,
        },
        {
          no: 2,
          title: 'Engineering',
          description: 'Minimize data analysis with the customer\'s basic design conditions in place and perform detailed layouts, facility specifications, simulations, control & information system engineering, detailed estimates, etc.',
          details: [
            'Proposed System Detail Layout',
            'Simulate overall system volume',
            'IT Solution Software Engineering',
            'Determination of investment size',
          ],
          chosen: false,
        },
      ],
      processes: [
        {
          title: 'Consulting request',
          callout: '01',
          icon: 'mdi-account-search',
        },
        {
          title: 'Consulting',
          callout: '02',
          icon: 'mdi-account-voice',
        },
        {
          title: 'On-site inspection',
          callout: '03',
          icon: 'mdi-store-search',
        },
        {
          title: 'Propose a consulting plan(Including Training)',
          callout: '04',
          icon: 'mdi-presentation',
        },
        {
          title: 'Consultation on the proposed plan',
          callout: '05',
          icon: 'mdi-clipboard-edit',
        },
        {
          title: 'Contract',
          callout: '06',
          icon: 'mdi-text-box-edit',
        },
        {
          title: 'Consultation',
          callout: '07',
          icon: 'mdi-human-male-board-poll',
        },
        {
          title: 'Interim Report',
          callout: '08',
          icon: 'mdi-chart-line',
        },
        {
          title: 'Final Completion',
          callout: '09',
          icon: 'mdi-check-all',
        },
        {
          title: 'After Sales Service and warranty',
          callout: '10',
          icon: 'mdi-phone-plus',
        },
      ],
    }),
    methods: {
      fnChangeTab (card) {
        this.currentTab = card.no
        this.cards[0].chosen = false
        this.cards[1].chosen = false
        this.cards[2].chosen = false
        card.chosen = true
      },
    },
  }
</script>
